import React from 'react'
import "./ModalBtn.css"

const ModalBtn = () => {
  return (
		<p className="text-center">
			<button
				type="button"
				className="btn btn-primary"
				data-bs-toggle="modal"
				data-bs-target="#exampleModal"
				id="trigger-btn"
			>
				Show Result
			</button>
		</p>
  );
}

export default ModalBtn