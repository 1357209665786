import React, { useContext } from "react";
import FlagsContext from "../../contexts/FlagsContext";

const BoxHeader = ({ formattedTime, points }) => {
  const flag = useContext(FlagsContext);
  return (
    <div className="card-header d-flex align-items-center justify-content-between">
      <span className="fs-4 text-uppercase">
        Which country's flag:{" "}
        <span id="turn-number">{flag.currentFlagIndex + 1}</span>/10
      </span>
      <div className="time-block input-group-text">
        <p className="time-block__text">Time: {formattedTime}</p>
      </div>
      <span className="input-group-text" id="basic-addon2">
        Point(s):{" "}
        <span className="ms-1" id="points">
          {points}
        </span>
      </span>
    </div>
  );
};

export default BoxHeader;
