import React, { useContext } from "react";
import FlagsContext from "../../contexts/FlagsContext";


const Flag = (props) => {
	const flag = useContext(FlagsContext);
	let currentFlagIndex = flag.currentFlagIndex
	return (
		<div className="card-body text-center pt-4">
			<img
				id="flag"
				className="edge-size"
				src={flag.randomFlags[currentFlagIndex].flags[0]}
				alt="country_flag"
			/>
		</div>
		
	);
};
export default Flag;