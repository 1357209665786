import React from "react";

const ModalBody = ({ formattedTime, points, handleRestart }) => {
	return (
		<div
			className="modal fade"
			id="exampleModal"
			tabIndex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title text-uppercase" id="exampleModalLabel">
							Game Result
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body text-center py-4" id="res-modal">
						{points < 6 ? (
							<p className="text-danger">Your final points is {points}. Your time: {formattedTime}. Sorry, you lose. 😫😥</p>
						) : (
							<p className="text-success">Your final points is {points}. Your time: {formattedTime}. Excellent, you win. 😊🎉🎊</p>
						)}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
							Close
						</button>
						<button
							type="button"
							className="btn btn-primary"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={handleRestart}
						>
							Retry
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalBody;

// onclick="handleRestart();" on retry
