export const data = [
    {
        "flags": ["./img/mt.png"
        ],
        "name": {
            "common": "Malta",
            "official": "Republic of Malta",
            "nativeName": {
                "eng": {
                    "official": "Republic of Malta",
                    "common": "Malta"
                },
                "mlt": {
                    "official": "Repubblika ta ' Malta",
                    "common": "Malta"
                }
            }
        }
    },
    {
        "flags": ["./img/sd.png"
        ],
        "name": {
            "common": "Sudan",
            "official": "Republic of the Sudan",
            "nativeName": {
                "ara": {
                    "official": "جمهورية السودان",
                    "common": "السودان"
                },
                "eng": {
                    "official": "Republic of the Sudan",
                    "common": "Sudan"
                }
            }
        }
    },
    {
        "flags": ["./img/nz.png"
        ],
        "name": {
            "common": "New Zealand",
            "official": "New Zealand",
            "nativeName": {
                "eng": {
                    "official": "New Zealand",
                    "common": "New Zealand"
                },
                "mri": {
                    "official": "Aotearoa",
                    "common": "Aotearoa"
                },
                "nzs": {
                    "official": "New Zealand",
                    "common": "New Zealand"
                }
            }
        }
    },
    {
        "flags": ["./img/iq.png"
        ],
        "name": {
            "common": "Iraq",
            "official": "Republic of Iraq",
            "nativeName": {
                "ara": {
                    "official": "جمهورية العراق",
                    "common": "العراق"
                },
                "arc": {
                    "official": "ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ",
                    "common": "ܩܘܼܛܢܵܐ"
                },
                "ckb": {
                    "official": "کۆماری عێراق",
                    "common": "کۆماری"
                }
            }
        }
    },
    {
        "flags": ["./img/gi.png"
        ],
        "name": {
            "common": "Gibraltar",
            "official": "Gibraltar",
            "nativeName": {
                "eng": {
                    "official": "Gibraltar",
                    "common": "Gibraltar"
                }
            }
        }
    },
    {
        "flags": ["./img/ru.png"
        ],
        "name": {
            "common": "Russia",
            "official": "Russian Federation",
            "nativeName": {
                "rus": {
                    "official": "Российская Федерация",
                    "common": "Россия"
                }
            }
        }
    },
    {
        "flags": ["./img/az.png"
        ],
        "name": {
            "common": "Azerbaijan",
            "official": "Republic of Azerbaijan",
            "nativeName": {
                "aze": {
                    "official": "Azərbaycan Respublikası",
                    "common": "Azərbaycan"
                },
                "rus": {
                    "official": "Азербайджанская Республика",
                    "common": "Азербайджан"
                }
            }
        }
    },
    {
        "flags": ["./img/io.png"
        ],
        "name": {
            "common": "British Indian Ocean Territory",
            "official": "British Indian Ocean Territory",
            "nativeName": {
                "eng": {
                    "official": "British Indian Ocean Territory",
                    "common": "British Indian Ocean Territory"
                }
            }
        }
    },
    {
        "flags": ["./img/ls.png"
        ],
        "name": {
            "common": "Lesotho",
            "official": "Kingdom of Lesotho",
            "nativeName": {
                "eng": {
                    "official": "Kingdom of Lesotho",
                    "common": "Lesotho"
                },
                "sot": {
                    "official": "Kingdom of Lesotho",
                    "common": "Lesotho"
                }
            }
        }
    },
    {
        "flags": ["./img/sk.png"
        ],
        "name": {
            "common": "Slovakia",
            "official": "Slovak Republic",
            "nativeName": {
                "slk": {
                    "official": "Slovenská republika",
                    "common": "Slovensko"
                }
            }
        }
    },
    {
        "flags": ["./img/um.png"
        ],
        "name": {
            "common": "United States Minor Outlying Islands",
            "official": "United States Minor Outlying Islands",
            "nativeName": {
                "eng": {
                    "official": "United States Minor Outlying Islands",
                    "common": "United States Minor Outlying Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/ge.png"
        ],
        "name": {
            "common": "Georgia",
            "official": "Georgia",
            "nativeName": {
                "kat": {
                    "official": "საქართველო",
                    "common": "საქართველო"
                }
            }
        }
    },
    {
        "flags": ["./img/dm.png"
        ],
        "name": {
            "common": "Dominica",
            "official": "Commonwealth of Dominica",
            "nativeName": {
                "eng": {
                    "official": "Commonwealth of Dominica",
                    "common": "Dominica"
                }
            }
        }
    },
    {
        "flags": ["./img/mz.png"
        ],
        "name": {
            "common": "Mozambique",
            "official": "Republic of Mozambique",
            "nativeName": {
                "por": {
                    "official": "República de Moçambique",
                    "common": "Moçambique"
                }
            }
        }
    },
    {
        "flags": ["./img/gl.png"
        ],
        "name": {
            "common": "Greenland",
            "official": "Greenland",
            "nativeName": {
                "kal": {
                    "official": "Kalaallit Nunaat",
                    "common": "Kalaallit Nunaat"
                }
            }
        }
    },
    {
        "flags": ["./img/ma.png"
        ],
        "name": {
            "common": "Morocco",
            "official": "Kingdom of Morocco",
            "nativeName": {
                "ara": {
                    "official": "المملكة المغربية",
                    "common": "المغرب"
                },
                "ber": {
                    "official": "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ",
                    "common": "ⵍⵎⴰⵖⵔⵉⴱ"
                }
            }
        }
    },
    {
        "flags": ["./img/ag.png"
        ],
        "name": {
            "common": "Antigua and Barbuda",
            "official": "Antigua and Barbuda",
            "nativeName": {
                "eng": {
                    "official": "Antigua and Barbuda",
                    "common": "Antigua and Barbuda"
                }
            }
        }
    },
    {
        "flags": ["./img/bm.png"
        ],
        "name": {
            "common": "Bermuda",
            "official": "Bermuda",
            "nativeName": {
                "eng": {
                    "official": "Bermuda",
                    "common": "Bermuda"
                }
            }
        }
    },
    {
        "flags": ["./img/zw.png"
        ],
        "name": {
            "common": "Zimbabwe",
            "official": "Republic of Zimbabwe",
            "nativeName": {
                "bwg": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "eng": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "kck": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "khi": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "ndc": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "nde": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "nya": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "sna": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "sot": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "toi": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "tsn": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "tso": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "ven": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "xho": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                },
                "zib": {
                    "official": "Republic of Zimbabwe",
                    "common": "Zimbabwe"
                }
            }
        }
    },
    {
        "flags": ["./img/id.png"
        ],
        "name": {
            "common": "Indonesia",
            "official": "Republic of Indonesia",
            "nativeName": {
                "ind": {
                    "official": "Republik Indonesia",
                    "common": "Indonesia"
                }
            }
        }
    },
    {
        "flags": ["./img/gy.png"
        ],
        "name": {
            "common": "Guyana",
            "official": "Co-operative Republic of Guyana",
            "nativeName": {
                "eng": {
                    "official": "Co-operative Republic of Guyana",
                    "common": "Guyana"
                }
            }
        }
    },
    {
        "flags": ["./img/gs.png"
        ],
        "name": {
            "common": "South Georgia",
            "official": "South Georgia and the South Sandwich Islands",
            "nativeName": {
                "eng": {
                    "official": "South Georgia and the South Sandwich Islands",
                    "common": "South Georgia"
                }
            }
        }
    },
    {
        "flags": ["./img/tn.png"
        ],
        "name": {
            "common": "Tunisia",
            "official": "Tunisian Republic",
            "nativeName": {
                "ara": {
                    "official": "الجمهورية التونسية",
                    "common": "تونس"
                }
            }
        }
    },
    {
        "flags": ["./img/ax.png"
        ],
        "name": {
            "common": "Åland Islands",
            "official": "Åland Islands",
            "nativeName": {
                "swe": {
                    "official": "Landskapet Åland",
                    "common": "Åland"
                }
            }
        }
    },
    {
        "flags": ["./img/vn.png"
        ],
        "name": {
            "common": "Vietnam",
            "official": "Socialist Republic of Vietnam",
            "nativeName": {
                "vie": {
                    "official": "Cộng hòa xã hội chủ nghĩa Việt Nam",
                    "common": "Việt Nam"
                }
            }
        }
    },
    {
        "flags": ["./img/ve.png"
        ],
        "name": {
            "common": "Venezuela",
            "official": "Bolivarian Republic of Venezuela",
            "nativeName": {
                "spa": {
                    "official": "República Bolivariana de Venezuela",
                    "common": "Venezuela"
                }
            }
        }
    },
    {
        "flags": ["./img/am.png"
        ],
        "name": {
            "common": "Armenia",
            "official": "Republic of Armenia",
            "nativeName": {
                "hye": {
                    "official": "Հայաստանի Հանրապետություն",
                    "common": "Հայաստան"
                }
            }
        }
    },
    {
        "flags": ["./img/fm.png"
        ],
        "name": {
            "common": "Micronesia",
            "official": "Federated States of Micronesia",
            "nativeName": {
                "eng": {
                    "official": "Federated States of Micronesia",
                    "common": "Micronesia"
                }
            }
        }
    },
    {
        "flags": ["./img/pg.png"
        ],
        "name": {
            "common": "Papua New Guinea",
            "official": "Independent State of Papua New Guinea",
            "nativeName": {
                "eng": {
                    "official": "Independent State of Papua New Guinea",
                    "common": "Papua New Guinea"
                },
                "hmo": {
                    "official": "Independen Stet bilong Papua Niugini",
                    "common": "Papua Niu Gini"
                },
                "tpi": {
                    "official": "Independen Stet bilong Papua Niugini",
                    "common": "Papua Niugini"
                }
            }
        }
    },
    {
        "flags": ["./img/sy.png"
        ],
        "name": {
            "common": "Syria",
            "official": "Syrian Arab Republic",
            "nativeName": {
                "ara": {
                    "official": "الجمهورية العربية السورية",
                    "common": "سوريا"
                }
            }
        }
    },
    {
        "flags": ["./img/pn.png"
        ],
        "name": {
            "common": "Pitcairn Islands",
            "official": "Pitcairn Group of Islands",
            "nativeName": {
                "eng": {
                    "official": "Pitcairn Group of Islands",
                    "common": "Pitcairn Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/bv.png"
        ],
        "name": {
            "common": "Bouvet Island",
            "official": "Bouvet Island",
            "nativeName": {
                "nor": {
                    "official": "Bouvetøya",
                    "common": "Bouvetøya"
                }
            }
        }
    },
    {
        "flags": ["./img/ch.png"
        ],
        "name": {
            "common": "Switzerland",
            "official": "Swiss Confederation",
            "nativeName": {
                "fra": {
                    "official": "Confédération suisse",
                    "common": "Suisse"
                },
                "gsw": {
                    "official": "Schweizerische Eidgenossenschaft",
                    "common": "Schweiz"
                },
                "ita": {
                    "official": "Confederazione Svizzera",
                    "common": "Svizzera"
                },
                "roh": {
                    "official": "Confederaziun svizra",
                    "common": "Svizra"
                }
            }
        }
    },
    {
        "flags": ["./img/aw.png"
        ],
        "name": {
            "common": "Aruba",
            "official": "Aruba",
            "nativeName": {
                "nld": {
                    "official": "Aruba",
                    "common": "Aruba"
                },
                "pap": {
                    "official": "Aruba",
                    "common": "Aruba"
                }
            }
        }
    },
    {
        "flags": ["./img/ee.png"
        ],
        "name": {
            "common": "Estonia",
            "official": "Republic of Estonia",
            "nativeName": {
                "est": {
                    "official": "Eesti Vabariik",
                    "common": "Eesti"
                }
            }
        }
    },
    {
        "flags": ["./img/ai.png"
        ],
        "name": {
            "common": "Anguilla",
            "official": "Anguilla",
            "nativeName": {
                "eng": {
                    "official": "Anguilla",
                    "common": "Anguilla"
                }
            }
        }
    },
    {
        "flags": ["./img/vu.png"
        ],
        "name": {
            "common": "Vanuatu",
            "official": "Republic of Vanuatu",
            "nativeName": {
                "bis": {
                    "official": "Ripablik blong Vanuatu",
                    "common": "Vanuatu"
                },
                "eng": {
                    "official": "Republic of Vanuatu",
                    "common": "Vanuatu"
                },
                "fra": {
                    "official": "République de Vanuatu",
                    "common": "Vanuatu"
                }
            }
        }
    },
    {
        "flags": ["./img/lv.png"
        ],
        "name": {
            "common": "Latvia",
            "official": "Republic of Latvia",
            "nativeName": {
                "lav": {
                    "official": "Latvijas Republikas",
                    "common": "Latvija"
                }
            }
        }
    },
    {
        "flags": ["./img/li.png"
        ],
        "name": {
            "common": "Liechtenstein",
            "official": "Principality of Liechtenstein",
            "nativeName": {
                "deu": {
                    "official": "Fürstentum Liechtenstein",
                    "common": "Liechtenstein"
                }
            }
        }
    },
    {
        "flags": ["./img/ck.png"
        ],
        "name": {
            "common": "Cook Islands",
            "official": "Cook Islands",
            "nativeName": {
                "eng": {
                    "official": "Cook Islands",
                    "common": "Cook Islands"
                },
                "rar": {
                    "official": "Kūki 'Āirani",
                    "common": "Kūki 'Āirani"
                }
            }
        }
    },
    {
        "flags": ["./img/yt.png"
        ],
        "name": {
            "common": "Mayotte",
            "official": "Department of Mayotte",
            "nativeName": {
                "fra": {
                    "official": "Département de Mayotte",
                    "common": "Mayotte"
                }
            }
        }
    },
    {
        "flags": ["./img/et.png"
        ],
        "name": {
            "common": "Ethiopia",
            "official": "Federal Democratic Republic of Ethiopia",
            "nativeName": {
                "amh": {
                    "official": "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
                    "common": "ኢትዮጵያ"
                }
            }
        }
    },
    {
        "flags": ["./img/ar.png"
        ],
        "name": {
            "common": "Argentina",
            "official": "Argentine Republic",
            "nativeName": {
                "grn": {
                    "official": "Argentine Republic",
                    "common": "Argentina"
                },
                "spa": {
                    "official": "República Argentina",
                    "common": "Argentina"
                }
            }
        }
    },
    {
        "flags": ["./img/im.png"
        ],
        "name": {
            "common": "Isle of Man",
            "official": "Isle of Man",
            "nativeName": {
                "eng": {
                    "official": "Isle of Man",
                    "common": "Isle of Man"
                },
                "glv": {
                    "official": "Ellan Vannin or Mannin",
                    "common": "Mannin"
                }
            }
        }
    },
    {
        "flags": ["./img/kn.png"
        ],
        "name": {
            "common": "Saint Kitts and Nevis",
            "official": "Federation of Saint Christopher and Nevis",
            "nativeName": {
                "eng": {
                    "official": "Federation of Saint Christopher and Nevis",
                    "common": "Saint Kitts and Nevis"
                }
            }
        }
    },
    {
        "flags": ["./img/pl.png"
        ],
        "name": {
            "common": "Poland",
            "official": "Republic of Poland",
            "nativeName": {
                "pol": {
                    "official": "Rzeczpospolita Polska",
                    "common": "Polska"
                }
            }
        }
    },
    {
        "flags": ["./img/rw.png"
        ],
        "name": {
            "common": "Rwanda",
            "official": "Republic of Rwanda",
            "nativeName": {
                "eng": {
                    "official": "Republic of Rwanda",
                    "common": "Rwanda"
                },
                "fra": {
                    "official": "République rwandaise",
                    "common": "Rwanda"
                },
                "kin": {
                    "official": "Repubulika y'u Rwanda",
                    "common": "Rwanda"
                }
            }
        }
    },
    {
        "flags": ["./img/ao.png"
        ],
        "name": {
            "common": "Angola",
            "official": "Republic of Angola",
            "nativeName": {
                "por": {
                    "official": "República de Angola",
                    "common": "Angola"
                }
            }
        }
    },
    {
        "flags": ["./img/eh.png"
        ],
        "name": {
            "common": "Western Sahara",
            "official": "Sahrawi Arab Democratic Republic",
            "nativeName": {
                "ber": {
                    "official": "Sahrawi Arab Democratic Republic",
                    "common": "Western Sahara"
                },
                "mey": {
                    "official": "الجمهورية العربية الصحراوية الديمقراطية",
                    "common": "الصحراء الغربية"
                },
                "spa": {
                    "official": "República Árabe Saharaui Democrática",
                    "common": "Sahara Occidental"
                }
            }
        }
    },
    {
        "flags": ["./img/hm.png"
        ],
        "name": {
            "common": "Heard Island and McDonald Islands",
            "official": "Heard Island and McDonald Islands",
            "nativeName": {
                "eng": {
                    "official": "Heard Island and McDonald Islands",
                    "common": "Heard Island and McDonald Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/th.png"
        ],
        "name": {
            "common": "Thailand",
            "official": "Kingdom of Thailand",
            "nativeName": {
                "tha": {
                    "official": "ราชอาณาจักรไทย",
                    "common": "ประเทศไทย"
                }
            }
        }
    },
    {
        "flags": ["./img/sc.png"
        ],
        "name": {
            "common": "Seychelles",
            "official": "Republic of Seychelles",
            "nativeName": {
                "crs": {
                    "official": "Repiblik Sesel",
                    "common": "Sesel"
                },
                "eng": {
                    "official": "Republic of Seychelles",
                    "common": "Seychelles"
                },
                "fra": {
                    "official": "République des Seychelles",
                    "common": "Seychelles"
                }
            }
        }
    },
    {
        "flags": ["./img/vi.png"
        ],
        "name": {
            "common": "United States Virgin Islands",
            "official": "Virgin Islands of the United States",
            "nativeName": {
                "eng": {
                    "official": "Virgin Islands of the United States",
                    "common": "United States Virgin Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/tg.png"
        ],
        "name": {
            "common": "Togo",
            "official": "Togolese Republic",
            "nativeName": {
                "fra": {
                    "official": "République togolaise",
                    "common": "Togo"
                }
            }
        }
    },
    {
        "flags": ["./img/in.png"
        ],
        "name": {
            "common": "India",
            "official": "Republic of India",
            "nativeName": {
                "eng": {
                    "official": "Republic of India",
                    "common": "India"
                },
                "hin": {
                    "official": "भारत गणराज्य",
                    "common": "भारत"
                },
                "tam": {
                    "official": "இந்தியக் குடியரசு",
                    "common": "இந்தியா"
                }
            }
        }
    },
    {
        "flags": ["./img/is.png"
        ],
        "name": {
            "common": "Iceland",
            "official": "Iceland",
            "nativeName": {
                "isl": {
                    "official": "Ísland",
                    "common": "Ísland"
                }
            }
        }
    },
    {
        "flags": ["./img/bh.png"
        ],
        "name": {
            "common": "Bahrain",
            "official": "Kingdom of Bahrain",
            "nativeName": {
                "ara": {
                    "official": "مملكة البحرين",
                    "common": "‏البحرين"
                }
            }
        }
    },
    {
        "flags": ["./img/br.png"
        ],
        "name": {
            "common": "Brazil",
            "official": "Federative Republic of Brazil",
            "nativeName": {
                "por": {
                    "official": "República Federativa do Brasil",
                    "common": "Brasil"
                }
            }
        }
    },
    {
        "flags": ["./img/ky.png"
        ],
        "name": {
            "common": "Cayman Islands",
            "official": "Cayman Islands",
            "nativeName": {
                "eng": {
                    "official": "Cayman Islands",
                    "common": "Cayman Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/sh.png"
        ],
        "name": {
            "common": "Saint Helena, Ascension and Tristan da Cunha",
            "official": "Saint Helena, Ascension and Tristan da Cunha",
            "nativeName": {
                "eng": {
                    "official": "Saint Helena, Ascension and Tristan da Cunha",
                    "common": "Saint Helena, Ascension and Tristan da Cunha"
                }
            }
        }
    },
    {
        "flags": ["./img/sx.png"
        ],
        "name": {
            "common": "Sint Maarten",
            "official": "Sint Maarten",
            "nativeName": {
                "eng": {
                    "official": "Sint Maarten",
                    "common": "Sint Maarten"
                },
                "fra": {
                    "official": "Saint-Martin",
                    "common": "Saint-Martin"
                },
                "nld": {
                    "official": "Sint Maarten",
                    "common": "Sint Maarten"
                }
            }
        }
    },
    {
        "flags": ["./img/ca.png"
        ],
        "name": {
            "common": "Canada",
            "official": "Canada",
            "nativeName": {
                "eng": {
                    "official": "Canada",
                    "common": "Canada"
                },
                "fra": {
                    "official": "Canada",
                    "common": "Canada"
                }
            }
        }
    },
    {
        "flags": ["./img/md.png"
        ],
        "name": {
            "common": "Moldova",
            "official": "Republic of Moldova",
            "nativeName": {
                "ron": {
                    "official": "Republica Moldova",
                    "common": "Moldova"
                }
            }
        }
    },
    {
        "flags": ["./img/nc.png"
        ],
        "name": {
            "common": "New Caledonia",
            "official": "New Caledonia",
            "nativeName": {
                "fra": {
                    "official": "Nouvelle-Calédonie",
                    "common": "Nouvelle-Calédonie"
                }
            }
        }
    },
    {
        "flags": ["./img/tt.png"
        ],
        "name": {
            "common": "Trinidad and Tobago",
            "official": "Republic of Trinidad and Tobago",
            "nativeName": {
                "eng": {
                    "official": "Republic of Trinidad and Tobago",
                    "common": "Trinidad and Tobago"
                }
            }
        }
    },
    {
        "flags": ["./img/tz.png"
        ],
        "name": {
            "common": "Tanzania",
            "official": "United Republic of Tanzania",
            "nativeName": {
                "eng": {
                    "official": "United Republic of Tanzania",
                    "common": "Tanzania"
                },
                "swa": {
                    "official": "Jamhuri ya Muungano wa Tanzania",
                    "common": "Tanzania"
                }
            }
        }
    },
    {
        "flags": ["./img/mn.png"
        ],
        "name": {
            "common": "Mongolia",
            "official": "Mongolia",
            "nativeName": {
                "mon": {
                    "official": "Монгол улс",
                    "common": "Монгол улс"
                }
            }
        }
    },
    {
        "flags": ["./img/sg.png"
        ],
        "name": {
            "common": "Singapore",
            "official": "Republic of Singapore",
            "nativeName": {
                "zho": {
                    "official": "新加坡共和国",
                    "common": "新加坡"
                },
                "eng": {
                    "official": "Republic of Singapore",
                    "common": "Singapore"
                },
                "msa": {
                    "official": "Republik Singapura",
                    "common": "Singapura"
                },
                "tam": {
                    "official": "சிங்கப்பூர் குடியரசு",
                    "common": "சிங்கப்பூர்"
                }
            }
        }
    },
    {
        "flags": ["./img/la.png"
        ],
        "name": {
            "common": "Laos",
            "official": "Lao People's Democratic Republic",
            "nativeName": {
                "lao": {
                    "official": "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
                    "common": "ສປປລາວ"
                }
            }
        }
    },
    {
        "flags": ["./img/kp.png"
        ],
        "name": {
            "common": "North Korea",
            "official": "Democratic People's Republic of Korea",
            "nativeName": {
                "kor": {
                    "official": "조선민주주의인민공화국",
                    "common": "조선"
                }
            }
        }
    },
    {
        "flags": ["./img/bo.png"
        ],
        "name": {
            "common": "Bolivia",
            "official": "Plurinational State of Bolivia",
            "nativeName": {
                "aym": {
                    "official": "Wuliwya Suyu",
                    "common": "Wuliwya"
                },
                "grn": {
                    "official": "Tetã Volívia",
                    "common": "Volívia"
                },
                "que": {
                    "official": "Buliwya Mamallaqta",
                    "common": "Buliwya"
                },
                "spa": {
                    "official": "Estado Plurinacional de Bolivia",
                    "common": "Bolivia"
                }
            }
        }
    },
    {
        "flags": ["./img/bs.png"
        ],
        "name": {
            "common": "Bahamas",
            "official": "Commonwealth of the Bahamas",
            "nativeName": {
                "eng": {
                    "official": "Commonwealth of the Bahamas",
                    "common": "Bahamas"
                }
            }
        }
    },
    {
        "flags": ["./img/kg.png"
        ],
        "name": {
            "common": "Kyrgyzstan",
            "official": "Kyrgyz Republic",
            "nativeName": {
                "kir": {
                    "official": "Кыргыз Республикасы",
                    "common": "Кыргызстан"
                },
                "rus": {
                    "official": "Кыргызская Республика",
                    "common": "Киргизия"
                }
            }
        }
    },
    {
        "flags": ["./img/uz.png"
        ],
        "name": {
            "common": "Uzbekistan",
            "official": "Republic of Uzbekistan",
            "nativeName": {
                "rus": {
                    "official": "Республика Узбекистан",
                    "common": "Узбекистан"
                },
                "uzb": {
                    "official": "O'zbekiston Respublikasi",
                    "common": "O‘zbekiston"
                }
            }
        }
    },
    {
        "flags": ["./img/py.png"
        ],
        "name": {
            "common": "Paraguay",
            "official": "Republic of Paraguay",
            "nativeName": {
                "grn": {
                    "official": "Tetã Paraguái",
                    "common": "Paraguái"
                },
                "spa": {
                    "official": "República de Paraguay",
                    "common": "Paraguay"
                }
            }
        }
    },
    {
        "flags": ["./img/gf.png"
        ],
        "name": {
            "common": "French Guiana",
            "official": "Guiana",
            "nativeName": {
                "fra": {
                    "official": "Guyane",
                    "common": "Guyane française"
                }
            }
        }
    },
    {
        "flags": ["./img/hk.png"
        ],
        "name": {
            "common": "Hong Kong",
            "official": "Hong Kong Special Administrative Region of the People's Republic of China",
            "nativeName": {
                "eng": {
                    "official": "Hong Kong Special Administrative Region of the People's Republic of China",
                    "common": "Hong Kong"
                },
                "zho": {
                    "official": "中华人民共和国香港特别行政区",
                    "common": "香港"
                }
            }
        }
    },
    {
        "flags": ["./img/cf.png"
        ],
        "name": {
            "common": "Central African Republic",
            "official": "Central African Republic",
            "nativeName": {
                "fra": {
                    "official": "République centrafricaine",
                    "common": "République centrafricaine"
                },
                "sag": {
                    "official": "Ködörösêse tî Bêafrîka",
                    "common": "Bêafrîka"
                }
            }
        }
    },
    {
        "flags": ["./img/tl.png"
        ],
        "name": {
            "common": "Timor-Leste",
            "official": "Democratic Republic of Timor-Leste",
            "nativeName": {
                "por": {
                    "official": "República Democrática de Timor-Leste",
                    "common": "Timor-Leste"
                },
                "tet": {
                    "official": "Repúblika Demokrátika Timór-Leste",
                    "common": "Timór-Leste"
                }
            }
        }
    },
    {
        "flags": ["./img/mk.png"
        ],
        "name": {
            "common": "North Macedonia",
            "official": "Republic of North Macedonia",
            "nativeName": {
                "mkd": {
                    "official": "Република Северна Македонија",
                    "common": "Македонија"
                }
            }
        }
    },
    {
        "flags": ["./img/gm.png"
        ],
        "name": {
            "common": "Gambia",
            "official": "Republic of the Gambia",
            "nativeName": {
                "eng": {
                    "official": "Republic of the Gambia",
                    "common": "Gambia"
                }
            }
        }
    },
    {
        "flags": ["./img/by.png"
        ],
        "name": {
            "common": "Belarus",
            "official": "Republic of Belarus",
            "nativeName": {
                "bel": {
                    "official": "Рэспубліка Беларусь",
                    "common": "Белару́сь"
                },
                "rus": {
                    "official": "Республика Беларусь",
                    "common": "Беларусь"
                }
            }
        }
    },
    {
        "flags": ["./img/jm.png"
        ],
        "name": {
            "common": "Jamaica",
            "official": "Jamaica",
            "nativeName": {
                "eng": {
                    "official": "Jamaica",
                    "common": "Jamaica"
                },
                "jam": {
                    "official": "Jamaica",
                    "common": "Jamaica"
                }
            }
        }
    },
    {
        "flags": ["./img/bl.png"
        ],
        "name": {
            "common": "Saint Barthélemy",
            "official": "Collectivity of Saint Barthélemy",
            "nativeName": {
                "fra": {
                    "official": "Collectivité de Saint-Barthélemy",
                    "common": "Saint-Barthélemy"
                }
            }
        }
    },
    {
        "flags": ["./img/ly.png"
        ],
        "name": {
            "common": "Libya",
            "official": "State of Libya",
            "nativeName": {
                "ara": {
                    "official": "الدولة ليبيا",
                    "common": "‏ليبيا"
                }
            }
        }
    },
    {
        "flags": ["./img/om.png"
        ],
        "name": {
            "common": "Oman",
            "official": "Sultanate of Oman",
            "nativeName": {
                "ara": {
                    "official": "سلطنة عمان",
                    "common": "عمان"
                }
            }
        }
    },
    {
        "flags": ["./img/at.png"
        ],
        "name": {
            "common": "Austria",
            "official": "Republic of Austria",
            "nativeName": {
                "bar": {
                    "official": "Republik Österreich",
                    "common": "Österreich"
                }
            }
        }
    },
    {
        "flags": ["./img/va.png"
        ],
        "name": {
            "common": "Vatican City",
            "official": "Vatican City State",
            "nativeName": {
                "ita": {
                    "official": "Stato della Città del Vaticano",
                    "common": "Vaticano"
                },
                "lat": {
                    "official": "Status Civitatis Vaticanæ",
                    "common": "Vaticanæ"
                }
            }
        }
    },
    {
        "flags": ["./img/cc.png"
        ],
        "name": {
            "common": "Cocos (Keeling) Islands",
            "official": "Territory of the Cocos (Keeling) Islands",
            "nativeName": {
                "eng": {
                    "official": "Territory of the Cocos (Keeling) Islands",
                    "common": "Cocos (Keeling) Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/lc.png"
        ],
        "name": {
            "common": "Saint Lucia",
            "official": "Saint Lucia",
            "nativeName": {
                "eng": {
                    "official": "Saint Lucia",
                    "common": "Saint Lucia"
                }
            }
        }
    },
    {
        "flags": ["./img/ng.png"
        ],
        "name": {
            "common": "Nigeria",
            "official": "Federal Republic of Nigeria",
            "nativeName": {
                "eng": {
                    "official": "Federal Republic of Nigeria",
                    "common": "Nigeria"
                }
            }
        }
    },
    {
        "flags": ["./img/to.png"
        ],
        "name": {
            "common": "Tonga",
            "official": "Kingdom of Tonga",
            "nativeName": {
                "eng": {
                    "official": "Kingdom of Tonga",
                    "common": "Tonga"
                },
                "ton": {
                    "official": "Kingdom of Tonga",
                    "common": "Tonga"
                }
            }
        }
    },
    {
        "flags": ["./img/sm.png"
        ],
        "name": {
            "common": "San Marino",
            "official": "Republic of San Marino",
            "nativeName": {
                "ita": {
                    "official": "Repubblica di San Marino",
                    "common": "San Marino"
                }
            }
        }
    },
    {
        "flags": ["./img/ba.png"
        ],
        "name": {
            "common": "Bosnia and Herzegovina",
            "official": "Bosnia and Herzegovina",
            "nativeName": {
                "bos": {
                    "official": "Bosna i Hercegovina",
                    "common": "Bosna i Hercegovina"
                },
                "hrv": {
                    "official": "Bosna i Hercegovina",
                    "common": "Bosna i Hercegovina"
                },
                "srp": {
                    "official": "Боснa и Херцеговина",
                    "common": "Боснa и Херцеговина"
                }
            }
        }
    },
    {
        "flags": ["./img/ir.png"
        ],
        "name": {
            "common": "Iran",
            "official": "Islamic Republic of Iran",
            "nativeName": {
                "fas": {
                    "official": "جمهوری اسلامی ایران",
                    "common": "ایران"
                }
            }
        }
    },
    {
        "flags": ["./img/ad.png"
        ],
        "name": {
            "common": "Andorra",
            "official": "Principality of Andorra",
            "nativeName": {
                "cat": {
                    "official": "Principat d'Andorra",
                    "common": "Andorra"
                }
            }
        }
    },
    {
        "flags": ["./img/tr.png"
        ],
        "name": {
            "common": "Turkey",
            "official": "Republic of Turkey",
            "nativeName": {
                "tur": {
                    "official": "Türkiye Cumhuriyeti",
                    "common": "Türkiye"
                }
            }
        }
    },
    {
        "flags": ["./img/ne.png"
        ],
        "name": {
            "common": "Niger",
            "official": "Republic of Niger",
            "nativeName": {
                "fra": {
                    "official": "République du Niger",
                    "common": "Niger"
                }
            }
        }
    },
    {
        "flags": ["./img/nl.png"
        ],
        "name": {
            "common": "Netherlands",
            "official": "Kingdom of the Netherlands",
            "nativeName": {
                "nld": {
                    "official": "Koninkrijk der Nederlanden",
                    "common": "Nederland"
                }
            }
        }
    },
    {
        "flags": ["./img/no.png"
        ],
        "name": {
            "common": "Norway",
            "official": "Kingdom of Norway",
            "nativeName": {
                "nno": {
                    "official": "Kongeriket Noreg",
                    "common": "Noreg"
                },
                "nob": {
                    "official": "Kongeriket Norge",
                    "common": "Norge"
                },
                "smi": {
                    "official": "Norgga gonagasriika",
                    "common": "Norgga"
                }
            }
        }
    },
    {
        "flags": ["./img/pa.png"
        ],
        "name": {
            "common": "Panama",
            "official": "Republic of Panama",
            "nativeName": {
                "spa": {
                    "official": "República de Panamá",
                    "common": "Panamá"
                }
            }
        }
    },
    {
        "flags": ["./img/bj.png"
        ],
        "name": {
            "common": "Benin",
            "official": "Republic of Benin",
            "nativeName": {
                "fra": {
                    "official": "République du Bénin",
                    "common": "Bénin"
                }
            }
        }
    },
    {
        "flags": ["./img/ms.png"
        ],
        "name": {
            "common": "Montserrat",
            "official": "Montserrat",
            "nativeName": {
                "eng": {
                    "official": "Montserrat",
                    "common": "Montserrat"
                }
            }
        }
    },
    {
        "flags": ["./img/ph.png"
        ],
        "name": {
            "common": "Philippines",
            "official": "Republic of the Philippines",
            "nativeName": {
                "eng": {
                    "official": "Republic of the Philippines",
                    "common": "Philippines"
                },
                "fil": {
                    "official": "Republic of the Philippines",
                    "common": "Pilipinas"
                }
            }
        }
    },
    {
        "flags": ["./img/lk.png"
        ],
        "name": {
            "common": "Sri Lanka",
            "official": "Democratic Socialist Republic of Sri Lanka",
            "nativeName": {
                "sin": {
                    "official": "ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය",
                    "common": "ශ්‍රී ලංකාව"
                },
                "tam": {
                    "official": "இலங்கை சனநாயக சோசலிசக் குடியரசு",
                    "common": "இலங்கை"
                }
            }
        }
    },
    {
        "flags": ["./img/cx.png"
        ],
        "name": {
            "common": "Christmas Island",
            "official": "Territory of Christmas Island",
            "nativeName": {
                "eng": {
                    "official": "Territory of Christmas Island",
                    "common": "Christmas Island"
                }
            }
        }
    },
    {
        "flags": ["./img/co.png"
        ],
        "name": {
            "common": "Colombia",
            "official": "Republic of Colombia",
            "nativeName": {
                "spa": {
                    "official": "República de Colombia",
                    "common": "Colombia"
                }
            }
        }
    },
    {
        "flags": ["./img/hr.png"
        ],
        "name": {
            "common": "Croatia",
            "official": "Republic of Croatia",
            "nativeName": {
                "hrv": {
                    "official": "Republika Hrvatska",
                    "common": "Hrvatska"
                }
            }
        }
    },
    {
        "flags": ["./img/bf.png"
        ],
        "name": {
            "common": "Burkina Faso",
            "official": "Burkina Faso",
            "nativeName": {
                "fra": {
                    "official": "République du Burkina",
                    "common": "Burkina Faso"
                }
            }
        }
    },
    {
        "flags": ["./img/cl.png"
        ],
        "name": {
            "common": "Chile",
            "official": "Republic of Chile",
            "nativeName": {
                "spa": {
                    "official": "República de Chile",
                    "common": "Chile"
                }
            }
        }
    },
    {
        "flags": ["./img/gn.png"
        ],
        "name": {
            "common": "Guinea",
            "official": "Republic of Guinea",
            "nativeName": {
                "fra": {
                    "official": "République de Guinée",
                    "common": "Guinée"
                }
            }
        }
    },
    {
        "flags": ["./img/gb.png"
        ],
        "name": {
            "common": "United Kingdom",
            "official": "United Kingdom of Great Britain and Northern Ireland",
            "nativeName": {
                "eng": {
                    "official": "United Kingdom of Great Britain and Northern Ireland",
                    "common": "United Kingdom"
                }
            }
        }
    },
    {
        "flags": ["./img/dk.png"
        ],
        "name": {
            "common": "Denmark",
            "official": "Kingdom of Denmark",
            "nativeName": {
                "dan": {
                    "official": "Kongeriget Danmark",
                    "common": "Danmark"
                }
            }
        }
    },
    {
        "flags": ["./img/il.png"
        ],
        "name": {
            "common": "Israel",
            "official": "State of Israel",
            "nativeName": {
                "ara": {
                    "official": "دولة إسرائيل",
                    "common": "إسرائيل"
                },
                "heb": {
                    "official": "מדינת ישראל",
                    "common": "ישראל"
                }
            }
        }
    },
    {
        "flags": ["./img/uy.png"
        ],
        "name": {
            "common": "Uruguay",
            "official": "Oriental Republic of Uruguay",
            "nativeName": {
                "spa": {
                    "official": "República Oriental del Uruguay",
                    "common": "Uruguay"
                }
            }
        }
    },
    {
        "flags": ["./img/mv.png"
        ],
        "name": {
            "common": "Maldives",
            "official": "Republic of the Maldives",
            "nativeName": {
                "div": {
                    "official": "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
                    "common": "ދިވެހިރާއްޖޭގެ"
                }
            }
        }
    },
    {
        "flags": ["./img/sl.png"
        ],
        "name": {
            "common": "Sierra Leone",
            "official": "Republic of Sierra Leone",
            "nativeName": {
                "eng": {
                    "official": "Republic of Sierra Leone",
                    "common": "Sierra Leone"
                }
            }
        }
    },
    {
        "flags": ["./img/na.png"
        ],
        "name": {
            "common": "Namibia",
            "official": "Republic of Namibia",
            "nativeName": {
                "afr": {
                    "official": "Republiek van Namibië",
                    "common": "Namibië"
                },
                "deu": {
                    "official": "Republik Namibia",
                    "common": "Namibia"
                },
                "eng": {
                    "official": "Republic of Namibia",
                    "common": "Namibia"
                },
                "her": {
                    "official": "Republic of Namibia",
                    "common": "Namibia"
                },
                "hgm": {
                    "official": "Republic of Namibia",
                    "common": "Namibia"
                },
                "kwn": {
                    "official": "Republic of Namibia",
                    "common": "Namibia"
                },
                "loz": {
                    "official": "Republic of Namibia",
                    "common": "Namibia"
                },
                "ndo": {
                    "official": "Republic of Namibia",
                    "common": "Namibia"
                },
                "tsn": {
                    "official": "Lefatshe la Namibia",
                    "common": "Namibia"
                }
            }
        }
    },
    {
        "flags": ["./img/cr.png"
        ],
        "name": {
            "common": "Costa Rica",
            "official": "Republic of Costa Rica",
            "nativeName": {
                "spa": {
                    "official": "República de Costa Rica",
                    "common": "Costa Rica"
                }
            }
        }
    },
    {
        "flags": ["./img/ga.png"
        ],
        "name": {
            "common": "Gabon",
            "official": "Gabonese Republic",
            "nativeName": {
                "fra": {
                    "official": "République gabonaise",
                    "common": "Gabon"
                }
            }
        }
    },
    {
        "flags": ["./img/km.png"
        ],
        "name": {
            "common": "Comoros",
            "official": "Union of the Comoros",
            "nativeName": {
                "ara": {
                    "official": "الاتحاد القمري",
                    "common": "القمر‎"
                },
                "fra": {
                    "official": "Union des Comores",
                    "common": "Comores"
                },
                "zdj": {
                    "official": "Udzima wa Komori",
                    "common": "Komori"
                }
            }
        }
    },
    {
        "flags": ["./img/mw.png"
        ],
        "name": {
            "common": "Malawi",
            "official": "Republic of Malawi",
            "nativeName": {
                "eng": {
                    "official": "Republic of Malawi",
                    "common": "Malawi"
                },
                "nya": {
                    "official": "Chalo cha Malawi, Dziko la Malaŵi",
                    "common": "Malaŵi"
                }
            }
        }
    },
    {
        "flags": ["./img/tc.png"
        ],
        "name": {
            "common": "Turks and Caicos Islands",
            "official": "Turks and Caicos Islands",
            "nativeName": {
                "eng": {
                    "official": "Turks and Caicos Islands",
                    "common": "Turks and Caicos Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/xk.png"
        ],
        "name": {
            "common": "Kosovo",
            "official": "Republic of Kosovo",
            "nativeName": {
                "sqi": {
                    "official": "Republika e Kosovës",
                    "common": "Kosova"
                },
                "srp": {
                    "official": "Република Косово",
                    "common": "Косово"
                }
            }
        }
    },
    {
        "flags": ["./img/nr.png"
        ],
        "name": {
            "common": "Nauru",
            "official": "Republic of Nauru",
            "nativeName": {
                "eng": {
                    "official": "Republic of Nauru",
                    "common": "Nauru"
                },
                "nau": {
                    "official": "Republic of Nauru",
                    "common": "Nauru"
                }
            }
        }
    },
    {
        "flags": ["./img/ug.png"
        ],
        "name": {
            "common": "Uganda",
            "official": "Republic of Uganda",
            "nativeName": {
                "eng": {
                    "official": "Republic of Uganda",
                    "common": "Uganda"
                },
                "swa": {
                    "official": "Republic of Uganda",
                    "common": "Uganda"
                }
            }
        }
    },
    {
        "flags": ["./img/mo.png"
        ],
        "name": {
            "common": "Macau",
            "official": "Macao Special Administrative Region of the People's Republic of China",
            "nativeName": {
                "por": {
                    "official": "Região Administrativa Especial de Macau da República Popular da China",
                    "common": "Macau"
                },
                "zho": {
                    "official": "中华人民共和国澳门特别行政区",
                    "common": "澳门"
                }
            }
        }
    },
    {
        "flags": ["./img/ci.png"
        ],
        "name": {
            "common": "Ivory Coast",
            "official": "Republic of Côte d'Ivoire",
            "nativeName": {
                "fra": {
                    "official": "République de Côte d'Ivoire",
                    "common": "Côte d'Ivoire"
                }
            }
        }
    },
    {
        "flags": ["./img/cn.png"
        ],
        "name": {
            "common": "China",
            "official": "People's Republic of China",
            "nativeName": {
                "zho": {
                    "official": "中华人民共和国",
                    "common": "中国"
                }
            }
        }
    },
    {
        "flags": ["./img/je.png"
        ],
        "name": {
            "common": "Jersey",
            "official": "Bailiwick of Jersey",
            "nativeName": {
                "eng": {
                    "official": "Bailiwick of Jersey",
                    "common": "Jersey"
                },
                "fra": {
                    "official": "Bailliage de Jersey",
                    "common": "Jersey"
                },
                "nrf": {
                    "official": "Bailliage dé Jèrri",
                    "common": "Jèrri"
                }
            }
        }
    },
    {
        "flags": ["./img/fj.png"
        ],
        "name": {
            "common": "Fiji",
            "official": "Republic of Fiji",
            "nativeName": {
                "eng": {
                    "official": "Republic of Fiji",
                    "common": "Fiji"
                },
                "fij": {
                    "official": "Matanitu Tugalala o Viti",
                    "common": "Viti"
                },
                "hif": {
                    "official": "रिपब्लिक ऑफ फीजी",
                    "common": "फिजी"
                }
            }
        }
    },
    {
        "flags": ["./img/bi.png"
        ],
        "name": {
            "common": "Burundi",
            "official": "Republic of Burundi",
            "nativeName": {
                "fra": {
                    "official": "République du Burundi",
                    "common": "Burundi"
                },
                "run": {
                    "official": "Republika y'Uburundi ",
                    "common": "Uburundi"
                }
            }
        }
    },
    {
        "flags": ["./img/sr.png"
        ],
        "name": {
            "common": "Suriname",
            "official": "Republic of Suriname",
            "nativeName": {
                "nld": {
                    "official": "Republiek Suriname",
                    "common": "Suriname"
                }
            }
        }
    },
    {
        "flags": ["./img/qa.png"
        ],
        "name": {
            "common": "Qatar",
            "official": "State of Qatar",
            "nativeName": {
                "ara": {
                    "official": "دولة قطر",
                    "common": "قطر"
                }
            }
        }
    },
    {
        "flags": ["./img/cv.png"
        ],
        "name": {
            "common": "Cape Verde",
            "official": "Republic of Cabo Verde",
            "nativeName": {
                "por": {
                    "official": "República de Cabo Verde",
                    "common": "Cabo Verde"
                }
            }
        }
    },
    {
        "flags": ["./img/gt.png"
        ],
        "name": {
            "common": "Guatemala",
            "official": "Republic of Guatemala",
            "nativeName": {
                "spa": {
                    "official": "República de Guatemala",
                    "common": "Guatemala"
                }
            }
        }
    },
    {
        "flags": ["./img/mq.png"
        ],
        "name": {
            "common": "Martinique",
            "official": "Martinique",
            "nativeName": {
                "fra": {
                    "official": "Martinique",
                    "common": "Martinique"
                }
            }
        }
    },
    {
        "flags": ["./img/mg.png"
        ],
        "name": {
            "common": "Madagascar",
            "official": "Republic of Madagascar",
            "nativeName": {
                "fra": {
                    "official": "République de Madagascar",
                    "common": "Madagascar"
                },
                "mlg": {
                    "official": "Repoblikan'i Madagasikara",
                    "common": "Madagasikara"
                }
            }
        }
    },
    {
        "flags": ["./img/mp.png"
        ],
        "name": {
            "common": "Northern Mariana Islands",
            "official": "Commonwealth of the Northern Mariana Islands",
            "nativeName": {
                "cal": {
                    "official": "Commonwealth of the Northern Mariana Islands",
                    "common": "Northern Mariana Islands"
                },
                "cha": {
                    "official": "Sankattan Siha Na Islas Mariånas",
                    "common": "Na Islas Mariånas"
                },
                "eng": {
                    "official": "Commonwealth of the Northern Mariana Islands",
                    "common": "Northern Mariana Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/pt.png"
        ],
        "name": {
            "common": "Portugal",
            "official": "Portuguese Republic",
            "nativeName": {
                "por": {
                    "official": "República português",
                    "common": "Portugal"
                }
            }
        }
    },
    {
        "flags": ["./img/dz.png"
        ],
        "name": {
            "common": "Algeria",
            "official": "People's Democratic Republic of Algeria",
            "nativeName": {
                "ara": {
                    "official": "الجمهورية الديمقراطية الشعبية الجزائرية",
                    "common": "الجزائر"
                }
            }
        }
    },
    {
        "flags": ["./img/nf.png"
        ],
        "name": {
            "common": "Norfolk Island",
            "official": "Territory of Norfolk Island",
            "nativeName": {
                "eng": {
                    "official": "Territory of Norfolk Island",
                    "common": "Norfolk Island"
                },
                "pih": {
                    "official": "Teratri of Norf'k Ailen",
                    "common": "Norf'k Ailen"
                }
            }
        }
    },
    {
        "flags": ["./img/bq.png"
        ],
        "name": {
            "common": "Caribbean Netherlands",
            "official": "Bonaire, Sint Eustatius and Saba",
            "nativeName": {
                "nld": {
                    "official": "Bonaire, Sint Eustatius en Saba",
                    "common": "Caribisch Nederland"
                },
                "pap": {
                    "official": "Boneiru, Sint Eustatius y Saba",
                    "common": "Boneiru, Sint Eustatius y Saba"
                }
            }
        }
    },
    {
        "flags": ["./img/cg.png"
        ],
        "name": {
            "common": "Republic of the Congo",
            "official": "Republic of the Congo",
            "nativeName": {
                "fra": {
                    "official": "République du Congo",
                    "common": "République du Congo"
                },
                "kon": {
                    "official": "Repubilika ya Kongo",
                    "common": "Repubilika ya Kongo"
                },
                "lin": {
                    "official": "Republíki ya Kongó",
                    "common": "Republíki ya Kongó"
                }
            }
        }
    },
    {
        "flags": ["./img/ke.png"
        ],
        "name": {
            "common": "Kenya",
            "official": "Republic of Kenya",
            "nativeName": {
                "eng": {
                    "official": "Republic of Kenya",
                    "common": "Kenya"
                },
                "swa": {
                    "official": "Republic of Kenya",
                    "common": "Kenya"
                }
            }
        }
    },
    {
        "flags": ["./img/sv.png"
        ],
        "name": {
            "common": "El Salvador",
            "official": "Republic of El Salvador",
            "nativeName": {
                "spa": {
                    "official": "República de El Salvador",
                    "common": "El Salvador"
                }
            }
        }
    },
    {
        "flags": ["./img/fr.png"
        ],
        "name": {
            "common": "France",
            "official": "French Republic",
            "nativeName": {
                "fra": {
                    "official": "République française",
                    "common": "France"
                }
            }
        }
    },
    {
        "flags": ["./img/bt.png"
        ],
        "name": {
            "common": "Bhutan",
            "official": "Kingdom of Bhutan",
            "nativeName": {
                "dzo": {
                    "official": "འབྲུག་རྒྱལ་ཁབ་",
                    "common": "འབྲུག་ཡུལ་"
                }
            }
        }
    },
    {
        "flags": ["./img/ki.png"
        ],
        "name": {
            "common": "Kiribati",
            "official": "Independent and Sovereign Republic of Kiribati",
            "nativeName": {
                "eng": {
                    "official": "Independent and Sovereign Republic of Kiribati",
                    "common": "Kiribati"
                },
                "gil": {
                    "official": "Ribaberiki Kiribati",
                    "common": "Kiribati"
                }
            }
        }
    },
    {
        "flags": ["./img/kr.png"
        ],
        "name": {
            "common": "South Korea",
            "official": "Republic of Korea",
            "nativeName": {
                "kor": {
                    "official": "대한민국",
                    "common": "한국"
                }
            }
        }
    },
    {
        "flags": ["./img/lb.png"
        ],
        "name": {
            "common": "Lebanon",
            "official": "Lebanese Republic",
            "nativeName": {
                "ara": {
                    "official": "الجمهورية اللبنانية",
                    "common": "لبنان"
                },
                "fra": {
                    "official": "République libanaise",
                    "common": "Liban"
                }
            }
        }
    },
    {
        "flags": ["./img/eg.png"
        ],
        "name": {
            "common": "Egypt",
            "official": "Arab Republic of Egypt",
            "nativeName": {
                "ara": {
                    "official": "جمهورية مصر العربية",
                    "common": "مصر"
                }
            }
        }
    },
    {
        "flags": [
            "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png"
        ],
        "name": {
            "common": "Afghanistan",
            "official": "Islamic Republic of Afghanistan",
            "nativeName": {
                "prs": {
                    "official": "جمهوری اسلامی افغانستان",
                    "common": "افغانستان"
                },
                "pus": {
                    "official": "د افغانستان اسلامي جمهوریت",
                    "common": "افغانستان"
                },
                "tuk": {
                    "official": "Owganystan Yslam Respublikasy",
                    "common": "Owganystan"
                }
            }
        }
    },
    {
        "flags": ["./img/lr.png"
        ],
        "name": {
            "common": "Liberia",
            "official": "Republic of Liberia",
            "nativeName": {
                "eng": {
                    "official": "Republic of Liberia",
                    "common": "Liberia"
                }
            }
        }
    },
    {
        "flags": ["./img/ye.png"
        ],
        "name": {
            "common": "Yemen",
            "official": "Republic of Yemen",
            "nativeName": {
                "ara": {
                    "official": "الجمهورية اليمنية",
                    "common": "اليَمَن"
                }
            }
        }
    },
    {
        "flags": ["./img/er.png"
        ],
        "name": {
            "common": "Eritrea",
            "official": "State of Eritrea",
            "nativeName": {
                "ara": {
                    "official": "دولة إرتريا",
                    "common": "إرتريا‎"
                },
                "eng": {
                    "official": "State of Eritrea",
                    "common": "Eritrea"
                },
                "tir": {
                    "official": "ሃገረ ኤርትራ",
                    "common": "ኤርትራ"
                }
            }
        }
    },
    {
        "flags": ["./img/st.png"
        ],
        "name": {
            "common": "São Tomé and Príncipe",
            "official": "Democratic Republic of São Tomé and Príncipe",
            "nativeName": {
                "por": {
                    "official": "República Democrática do São Tomé e Príncipe",
                    "common": "São Tomé e Príncipe"
                }
            }
        }
    },
    {
        "flags": ["./img/sa.png"
        ],
        "name": {
            "common": "Saudi Arabia",
            "official": "Kingdom of Saudi Arabia",
            "nativeName": {
                "ara": {
                    "official": "المملكة العربية السعودية",
                    "common": "العربية السعودية"
                }
            }
        }
    },
    {
        "flags": ["./img/fo.png"
        ],
        "name": {
            "common": "Faroe Islands",
            "official": "Faroe Islands",
            "nativeName": {
                "dan": {
                    "official": "Færøerne",
                    "common": "Færøerne"
                },
                "fao": {
                    "official": "Føroyar",
                    "common": "Føroyar"
                }
            }
        }
    },
    {
        "flags": ["./img/sb.png"
        ],
        "name": {
            "common": "Solomon Islands",
            "official": "Solomon Islands",
            "nativeName": {
                "eng": {
                    "official": "Solomon Islands",
                    "common": "Solomon Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/bb.png"
        ],
        "name": {
            "common": "Barbados",
            "official": "Barbados",
            "nativeName": {
                "eng": {
                    "official": "Barbados",
                    "common": "Barbados"
                }
            }
        }
    },
    {
        "flags": ["./img/cy.png"
        ],
        "name": {
            "common": "Cyprus",
            "official": "Republic of Cyprus",
            "nativeName": {
                "ell": {
                    "official": "Δημοκρατία της Κύπρος",
                    "common": "Κύπρος"
                },
                "tur": {
                    "official": "Kıbrıs Cumhuriyeti",
                    "common": "Kıbrıs"
                }
            }
        }
    },
    {
        "flags": ["./img/aq.png"
        ],
        "name": {
            "common": "Antarctica",
            "official": "Antarctica",
            "nativeName": {}
        }
    },
    {
        "flags": ["./img/me.png"
        ],
        "name": {
            "common": "Montenegro",
            "official": "Montenegro",
            "nativeName": {
                "cnr": {
                    "official": "Црна Гора",
                    "common": "Црна Гора"
                }
            }
        }
    },
    {
        "flags": ["./img/cu.png"
        ],
        "name": {
            "common": "Cuba",
            "official": "Republic of Cuba",
            "nativeName": {
                "spa": {
                    "official": "República de Cuba",
                    "common": "Cuba"
                }
            }
        }
    },
    {
        "flags": ["./img/fk.png"
        ],
        "name": {
            "common": "Falkland Islands",
            "official": "Falkland Islands",
            "nativeName": {
                "eng": {
                    "official": "Falkland Islands",
                    "common": "Falkland Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/si.png"
        ],
        "name": {
            "common": "Slovenia",
            "official": "Republic of Slovenia",
            "nativeName": {
                "slv": {
                    "official": "Republika Slovenija",
                    "common": "Slovenija"
                }
            }
        }
    },
    {
        "flags": ["./img/al.png"
        ],
        "name": {
            "common": "Albania",
            "official": "Republic of Albania",
            "nativeName": {
                "sqi": {
                    "official": "Republika e Shqipërisë",
                    "common": "Shqipëria"
                }
            }
        }
    },
    {
        "flags": ["./img/sz.png"
        ],
        "name": {
            "common": "Eswatini",
            "official": "Kingdom of Eswatini",
            "nativeName": {
                "eng": {
                    "official": "Kingdom of Eswatini",
                    "common": "Eswatini"
                },
                "ssw": {
                    "official": "Umbuso weSwatini",
                    "common": "eSwatini"
                }
            }
        }
    },
    {
        "flags": ["./img/kh.png"
        ],
        "name": {
            "common": "Cambodia",
            "official": "Kingdom of Cambodia",
            "nativeName": {
                "khm": {
                    "official": "ព្រះរាជាណាចក្រកម្ពុជា",
                    "common": "Kâmpŭchéa"
                }
            }
        }
    },
    {
        "flags": ["./img/cz.png"
        ],
        "name": {
            "common": "Czechia",
            "official": "Czech Republic",
            "nativeName": {
                "ces": {
                    "official": "Česká republika",
                    "common": "Česko"
                },
                "slk": {
                    "official": "Česká republika",
                    "common": "Česko"
                }
            }
        }
    },
    {
        "flags": ["./img/gr.png"
        ],
        "name": {
            "common": "Greece",
            "official": "Hellenic Republic",
            "nativeName": {
                "ell": {
                    "official": "Ελληνική Δημοκρατία",
                    "common": "Ελλάδα"
                }
            }
        }
    },
    {
        "flags": ["./img/rs.png"
        ],
        "name": {
            "common": "Serbia",
            "official": "Republic of Serbia",
            "nativeName": {
                "srp": {
                    "official": "Република Србија",
                    "common": "Србија"
                }
            }
        }
    },
    {
        "flags": ["./img/mx.png"
        ],
        "name": {
            "common": "Mexico",
            "official": "United Mexican States",
            "nativeName": {
                "spa": {
                    "official": "Estados Unidos Mexicanos",
                    "common": "México"
                }
            }
        }
    },
    {
        "flags": ["./img/re.png"
        ],
        "name": {
            "common": "Réunion",
            "official": "Réunion Island",
            "nativeName": {
                "fra": {
                    "official": "Ile de la Réunion",
                    "common": "La Réunion"
                }
            }
        }
    },
    {
        "flags": ["./img/tm.png"
        ],
        "name": {
            "common": "Turkmenistan",
            "official": "Turkmenistan",
            "nativeName": {
                "rus": {
                    "official": "Туркменистан",
                    "common": "Туркмения"
                },
                "tuk": {
                    "official": "Türkmenistan",
                    "common": "Türkmenistan"
                }
            }
        }
    },
    {
        "flags": ["./img/tf.png"
        ],
        "name": {
            "common": "French Southern and Antarctic Lands",
            "official": "Territory of the French Southern and Antarctic Lands",
            "nativeName": {
                "fra": {
                    "official": "Territoire des Terres australes et antarctiques françaises",
                    "common": "Terres australes et antarctiques françaises"
                }
            }
        }
    },
    {
        "flags": ["./img/mr.png"
        ],
        "name": {
            "common": "Mauritania",
            "official": "Islamic Republic of Mauritania",
            "nativeName": {
                "ara": {
                    "official": "الجمهورية الإسلامية الموريتانية",
                    "common": "موريتانيا"
                }
            }
        }
    },
    {
        "flags": ["./img/ml.png"
        ],
        "name": {
            "common": "Mali",
            "official": "Republic of Mali",
            "nativeName": {
                "fra": {
                    "official": "République du Mali",
                    "common": "Mali"
                }
            }
        }
    },
    {
        "flags": ["./img/gd.png"
        ],
        "name": {
            "common": "Grenada",
            "official": "Grenada",
            "nativeName": {
                "eng": {
                    "official": "Grenada",
                    "common": "Grenada"
                }
            }
        }
    },
    {
        "flags": ["./img/gu.png"
        ],
        "name": {
            "common": "Guam",
            "official": "Guam",
            "nativeName": {
                "cha": {
                    "official": "Guåhån",
                    "common": "Guåhån"
                },
                "eng": {
                    "official": "Guam",
                    "common": "Guam"
                },
                "spa": {
                    "official": "Guam",
                    "common": "Guam"
                }
            }
        }
    },
    {
        "flags": ["./img/gq.png"
        ],
        "name": {
            "common": "Equatorial Guinea",
            "official": "Republic of Equatorial Guinea",
            "nativeName": {
                "fra": {
                    "official": "République de la Guinée Équatoriale",
                    "common": "Guinée équatoriale"
                },
                "por": {
                    "official": "República da Guiné Equatorial",
                    "common": "Guiné Equatorial"
                },
                "spa": {
                    "official": "República de Guinea Ecuatorial",
                    "common": "Guinea Ecuatorial"
                }
            }
        }
    },
    {
        "flags": ["./img/it.png"
        ],
        "name": {
            "common": "Italy",
            "official": "Italian Republic",
            "nativeName": {
                "ita": {
                    "official": "Repubblica italiana",
                    "common": "Italia"
                }
            }
        }
    },
    {
        "flags": ["./img/pm.png"
        ],
        "name": {
            "common": "Saint Pierre and Miquelon",
            "official": "Saint Pierre and Miquelon",
            "nativeName": {
                "fra": {
                    "official": "Collectivité territoriale de Saint-Pierre-et-Miquelon",
                    "common": "Saint-Pierre-et-Miquelon"
                }
            }
        }
    },
    {
        "flags": ["./img/be.png"
        ],
        "name": {
            "common": "Belgium",
            "official": "Kingdom of Belgium",
            "nativeName": {
                "deu": {
                    "official": "Königreich Belgien",
                    "common": "Belgien"
                },
                "fra": {
                    "official": "Royaume de Belgique",
                    "common": "Belgique"
                },
                "nld": {
                    "official": "Koninkrijk België",
                    "common": "België"
                }
            }
        }
    },
    {
        "flags": ["./img/kw.png"
        ],
        "name": {
            "common": "Kuwait",
            "official": "State of Kuwait",
            "nativeName": {
                "ara": {
                    "official": "دولة الكويت",
                    "common": "الكويت"
                }
            }
        }
    },
    {
        "flags": ["./img/pw.png"
        ],
        "name": {
            "common": "Palau",
            "official": "Republic of Palau",
            "nativeName": {
                "eng": {
                    "official": "Republic of Palau",
                    "common": "Palau"
                },
                "pau": {
                    "official": "Beluu er a Belau",
                    "common": "Belau"
                }
            }
        }
    },
    {
        "flags": ["./img/us.png"
        ],
        "name": {
            "common": "United States",
            "official": "United States of America",
            "nativeName": {
                "eng": {
                    "official": "United States of America",
                    "common": "United States"
                }
            }
        }
    },
    {
        "flags": ["./img/ae.png"
        ],
        "name": {
            "common": "United Arab Emirates",
            "official": "United Arab Emirates",
            "nativeName": {
                "ara": {
                    "official": "الإمارات العربية المتحدة",
                    "common": "دولة الإمارات العربية المتحدة"
                }
            }
        }
    },
    {
        "flags": ["./img/kz.png"
        ],
        "name": {
            "common": "Kazakhstan",
            "official": "Republic of Kazakhstan",
            "nativeName": {
                "kaz": {
                    "official": "Қазақстан Республикасы",
                    "common": "Қазақстан"
                },
                "rus": {
                    "official": "Республика Казахстан",
                    "common": "Казахстан"
                }
            }
        }
    },
    {
        "flags": ["./img/tw.png"
        ],
        "name": {
            "common": "Taiwan",
            "official": "Republic of China (Taiwan)",
            "nativeName": {
                "zho": {
                    "official": "中華民國",
                    "common": "台灣"
                }
            }
        }
    },
    {
        "flags": ["./img/gg.png"
        ],
        "name": {
            "common": "Guernsey",
            "official": "Bailiwick of Guernsey",
            "nativeName": {
                "eng": {
                    "official": "Bailiwick of Guernsey",
                    "common": "Guernsey"
                },
                "fra": {
                    "official": "Bailliage de Guernesey",
                    "common": "Guernesey"
                },
                "nfr": {
                    "official": "Dgèrnésiais",
                    "common": "Dgèrnésiais"
                }
            }
        }
    },
    {
        "flags": ["./img/ro.png"
        ],
        "name": {
            "common": "Romania",
            "official": "Romania",
            "nativeName": {
                "ron": {
                    "official": "România",
                    "common": "România"
                }
            }
        }
    },
    {
        "flags": ["./img/bn.png"
        ],
        "name": {
            "common": "Brunei",
            "official": "Nation of Brunei, Abode of Peace",
            "nativeName": {
                "msa": {
                    "official": "Nation of Brunei, Abode Damai",
                    "common": "Negara Brunei Darussalam"
                }
            }
        }
    },
    {
        "flags": ["./img/cm.png"
        ],
        "name": {
            "common": "Cameroon",
            "official": "Republic of Cameroon",
            "nativeName": {
                "eng": {
                    "official": "Republic of Cameroon",
                    "common": "Cameroon"
                },
                "fra": {
                    "official": "République du Cameroun",
                    "common": "Cameroun"
                }
            }
        }
    },
    {
        "flags": ["./img/vc.png"
        ],
        "name": {
            "common": "Saint Vincent and the Grenadines",
            "official": "Saint Vincent and the Grenadines",
            "nativeName": {
                "eng": {
                    "official": "Saint Vincent and the Grenadines",
                    "common": "Saint Vincent and the Grenadines"
                }
            }
        }
    },
    {
        "flags": ["./img/hn.png"
        ],
        "name": {
            "common": "Honduras",
            "official": "Republic of Honduras",
            "nativeName": {
                "spa": {
                    "official": "República de Honduras",
                    "common": "Honduras"
                }
            }
        }
    },
    {
        "flags": ["./img/nu.png"
        ],
        "name": {
            "common": "Niue",
            "official": "Niue",
            "nativeName": {
                "eng": {
                    "official": "Niue",
                    "common": "Niue"
                },
                "niu": {
                    "official": "Niuē",
                    "common": "Niuē"
                }
            }
        }
    },
    {
        "flags": ["./img/mu.png"
        ],
        "name": {
            "common": "Mauritius",
            "official": "Republic of Mauritius",
            "nativeName": {
                "eng": {
                    "official": "Republic of Mauritius",
                    "common": "Mauritius"
                },
                "fra": {
                    "official": "République de Maurice",
                    "common": "Maurice"
                },
                "mfe": {
                    "official": "Republik Moris",
                    "common": "Moris"
                }
            }
        }
    },
    {
        "flags": ["./img/so.png"
        ],
        "name": {
            "common": "Somalia",
            "official": "Federal Republic of Somalia",
            "nativeName": {
                "ara": {
                    "official": "جمهورية الصومال‎‎",
                    "common": "الصومال‎‎"
                },
                "som": {
                    "official": "Jamhuuriyadda Federaalka Soomaaliya",
                    "common": "Soomaaliya"
                }
            }
        }
    },
    {
        "flags": ["./img/mm.png"
        ],
        "name": {
            "common": "Myanmar",
            "official": "Republic of the Union of Myanmar",
            "nativeName": {
                "mya": {
                    "official": "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်",
                    "common": "မြန်မာ"
                }
            }
        }
    },
    {
        "flags": ["./img/ws.png"
        ],
        "name": {
            "common": "Samoa",
            "official": "Independent State of Samoa",
            "nativeName": {
                "eng": {
                    "official": "Independent State of Samoa",
                    "common": "Samoa"
                },
                "smo": {
                    "official": "Malo Saʻoloto Tutoʻatasi o Sāmoa",
                    "common": "Sāmoa"
                }
            }
        }
    },
    {
        "flags": ["./img/vg.png"
        ],
        "name": {
            "common": "British Virgin Islands",
            "official": "Virgin Islands",
            "nativeName": {
                "eng": {
                    "official": "Virgin Islands",
                    "common": "British Virgin Islands"
                }
            }
        }
    },
    {
        "flags": ["./img/se.png"
        ],
        "name": {
            "common": "Sweden",
            "official": "Kingdom of Sweden",
            "nativeName": {
                "swe": {
                    "official": "Konungariket Sverige",
                    "common": "Sverige"
                }
            }
        }
    },
    {
        "flags": ["./img/gw.png"
        ],
        "name": {
            "common": "Guinea-Bissau",
            "official": "Republic of Guinea-Bissau",
            "nativeName": {
                "por": {
                    "official": "República da Guiné-Bissau",
                    "common": "Guiné-Bissau"
                },
                "pov": {
                    "official": "República da Guiné-Bissau",
                    "common": "Guiné-Bissau"
                }
            }
        }
    },
    {
        "flags": ["./img/ie.png"
        ],
        "name": {
            "common": "Ireland",
            "official": "Republic of Ireland",
            "nativeName": {
                "eng": {
                    "official": "Republic of Ireland",
                    "common": "Ireland"
                },
                "gle": {
                    "official": "Poblacht na hÉireann",
                    "common": "Éire"
                }
            }
        }
    },
    {
        "flags": ["./img/za.png"
        ],
        "name": {
            "common": "South Africa",
            "official": "Republic of South Africa",
            "nativeName": {
                "afr": {
                    "official": "Republiek van Suid-Afrika",
                    "common": "South Africa"
                },
                "eng": {
                    "official": "Republic of South Africa",
                    "common": "South Africa"
                },
                "nbl": {
                    "official": "IRiphabliki yeSewula Afrika",
                    "common": "Sewula Afrika"
                },
                "nso": {
                    "official": "Rephaboliki ya Afrika-Borwa ",
                    "common": "Afrika-Borwa"
                },
                "sot": {
                    "official": "Rephaboliki ya Afrika Borwa",
                    "common": "Afrika Borwa"
                },
                "ssw": {
                    "official": "IRiphabhulikhi yeNingizimu Afrika",
                    "common": "Ningizimu Afrika"
                },
                "tsn": {
                    "official": "Rephaboliki ya Aforika Borwa",
                    "common": "Aforika Borwa"
                },
                "tso": {
                    "official": "Riphabliki ra Afrika Dzonga",
                    "common": "Afrika Dzonga"
                },
                "ven": {
                    "official": "Riphabuḽiki ya Afurika Tshipembe",
                    "common": "Afurika Tshipembe"
                },
                "xho": {
                    "official": "IRiphabliki yaseMzantsi Afrika",
                    "common": "Mzantsi Afrika"
                },
                "zul": {
                    "official": "IRiphabliki yaseNingizimu Afrika",
                    "common": "Ningizimu Afrika"
                }
            }
        }
    },
    {
        "flags": ["./img/ht.png"
        ],
        "name": {
            "common": "Haiti",
            "official": "Republic of Haiti",
            "nativeName": {
                "fra": {
                    "official": "République d'Haïti",
                    "common": "Haïti"
                },
                "hat": {
                    "official": "Repiblik Ayiti",
                    "common": "Ayiti"
                }
            }
        }
    },
    {
        "flags": ["./img/sj.png"
        ],
        "name": {
            "common": "Svalbard and Jan Mayen",
            "official": "Svalbard og Jan Mayen",
            "nativeName": {
                "nor": {
                    "official": "Svalbard og Jan Mayen",
                    "common": "Svalbard og Jan Mayen"
                }
            }
        }
    },
    {
        "flags": ["./img/wf.png"
        ],
        "name": {
            "common": "Wallis and Futuna",
            "official": "Territory of the Wallis and Futuna Islands",
            "nativeName": {
                "fra": {
                    "official": "Territoire des îles Wallis et Futuna",
                    "common": "Wallis et Futuna"
                }
            }
        }
    },
    {
        "flags": ["./img/bz.png"
        ],
        "name": {
            "common": "Belize",
            "official": "Belize",
            "nativeName": {
                "bjz": {
                    "official": "Belize",
                    "common": "Belize"
                },
                "eng": {
                    "official": "Belize",
                    "common": "Belize"
                },
                "spa": {
                    "official": "Belice",
                    "common": "Belice"
                }
            }
        }
    },
    {
        "flags": ["./img/fi.png"
        ],
        "name": {
            "common": "Finland",
            "official": "Republic of Finland",
            "nativeName": {
                "fin": {
                    "official": "Suomen tasavalta",
                    "common": "Suomi"
                },
                "swe": {
                    "official": "Republiken Finland",
                    "common": "Finland"
                }
            }
        }
    },
    {
        "flags": ["./img/au.png"
        ],
        "name": {
            "common": "Australia",
            "official": "Commonwealth of Australia",
            "nativeName": {
                "eng": {
                    "official": "Commonwealth of Australia",
                    "common": "Australia"
                }
            }
        }
    },
    {
        "flags": ["./img/bg.png"
        ],
        "name": {
            "common": "Bulgaria",
            "official": "Republic of Bulgaria",
            "nativeName": {
                "bul": {
                    "official": "Република България",
                    "common": "България"
                }
            }
        }
    },
    {
        "flags": ["./img/ps.png"
        ],
        "name": {
            "common": "Palestine",
            "official": "State of Palestine",
            "nativeName": {
                "ara": {
                    "official": "دولة فلسطين",
                    "common": "فلسطين"
                }
            }
        }
    },
    {
        "flags": ["./img/tj.png"
        ],
        "name": {
            "common": "Tajikistan",
            "official": "Republic of Tajikistan",
            "nativeName": {
                "rus": {
                    "official": "Республика Таджикистан",
                    "common": "Таджикистан"
                },
                "tgk": {
                    "official": "Ҷумҳурии Тоҷикистон",
                    "common": "Тоҷикистон"
                }
            }
        }
    },
    {
        "flags": ["./img/hu.png"
        ],
        "name": {
            "common": "Hungary",
            "official": "Hungary",
            "nativeName": {
                "hun": {
                    "official": "Magyarország",
                    "common": "Magyarország"
                }
            }
        }
    },
    {
        "flags": ["./img/dj.png"
        ],
        "name": {
            "common": "Djibouti",
            "official": "Republic of Djibouti",
            "nativeName": {
                "ara": {
                    "official": "جمهورية جيبوتي",
                    "common": "جيبوتي‎"
                },
                "fra": {
                    "official": "République de Djibouti",
                    "common": "Djibouti"
                }
            }
        }
    },
    {
        "flags": ["./img/pe.png"
        ],
        "name": {
            "common": "Peru",
            "official": "Republic of Peru",
            "nativeName": {
                "aym": {
                    "official": "Piruw Suyu",
                    "common": "Piruw"
                },
                "que": {
                    "official": "Piruw Ripuwlika",
                    "common": "Piruw"
                },
                "spa": {
                    "official": "República del Perú",
                    "common": "Perú"
                }
            }
        }
    },
    {
        "flags": ["./img/my.png"
        ],
        "name": {
            "common": "Malaysia",
            "official": "Malaysia",
            "nativeName": {
                "eng": {
                    "official": "Malaysia",
                    "common": "Malaysia"
                },
                "msa": {
                    "official": "مليسيا",
                    "common": "مليسيا"
                }
            }
        }
    },
    {
        "flags": ["./img/do.png"
        ],
        "name": {
            "common": "Dominican Republic",
            "official": "Dominican Republic",
            "nativeName": {
                "spa": {
                    "official": "República Dominicana",
                    "common": "República Dominicana"
                }
            }
        }
    },
    {
        "flags": ["./img/gp.png"
        ],
        "name": {
            "common": "Guadeloupe",
            "official": "Guadeloupe",
            "nativeName": {
                "fra": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                }
            }
        }
    },
    {
        "flags": ["./img/lu.png"
        ],
        "name": {
            "common": "Luxembourg",
            "official": "Grand Duchy of Luxembourg",
            "nativeName": {
                "deu": {
                    "official": "Großherzogtum Luxemburg",
                    "common": "Luxemburg"
                },
                "fra": {
                    "official": "Grand-Duché de Luxembourg",
                    "common": "Luxembourg"
                },
                "ltz": {
                    "official": "Groussherzogtum Lëtzebuerg",
                    "common": "Lëtzebuerg"
                }
            }
        }
    },
    {
        "flags": ["./img/ua.png"
        ],
        "name": {
            "common": "Ukraine",
            "official": "Ukraine",
            "nativeName": {
                "ukr": {
                    "official": "Україна",
                    "common": "Україна"
                }
            }
        }
    },
    {
        "flags": ["./img/cw.png"
        ],
        "name": {
            "common": "Curaçao",
            "official": "Country of Curaçao",
            "nativeName": {
                "eng": {
                    "official": "Country of Curaçao",
                    "common": "Curaçao"
                },
                "nld": {
                    "official": "Land Curaçao",
                    "common": "Curaçao"
                },
                "pap": {
                    "official": "Pais Kòrsou",
                    "common": "Pais Kòrsou"
                }
            }
        }
    },
    {
        "flags": ["./img/tk.png"
        ],
        "name": {
            "common": "Tokelau",
            "official": "Tokelau",
            "nativeName": {
                "eng": {
                    "official": "Tokelau",
                    "common": "Tokelau"
                },
                "smo": {
                    "official": "Tokelau",
                    "common": "Tokelau"
                },
                "tkl": {
                    "official": "Tokelau",
                    "common": "Tokelau"
                }
            }
        }
    },
    {
        "flags": ["./img/es.png"
        ],
        "name": {
            "common": "Spain",
            "official": "Kingdom of Spain",
            "nativeName": {
                "spa": {
                    "official": "Reino de España",
                    "common": "España"
                }
            }
        }
    },
    {
        "flags": ["./img/pr.png"
        ],
        "name": {
            "common": "Puerto Rico",
            "official": "Commonwealth of Puerto Rico",
            "nativeName": {
                "eng": {
                    "official": "Commonwealth of Puerto Rico",
                    "common": "Puerto Rico"
                },
                "spa": {
                    "official": "Estado Libre Asociado de Puerto Rico",
                    "common": "Puerto Rico"
                }
            }
        }
    },
    {
        "flags": ["./img/jp.png"
        ],
        "name": {
            "common": "Japan",
            "official": "Japan",
            "nativeName": {
                "jpn": {
                    "official": "日本",
                    "common": "日本"
                }
            }
        }
    },
    {
        "flags": ["./img/bw.png"
        ],
        "name": {
            "common": "Botswana",
            "official": "Republic of Botswana",
            "nativeName": {
                "eng": {
                    "official": "Republic of Botswana",
                    "common": "Botswana"
                },
                "tsn": {
                    "official": "Lefatshe la Botswana",
                    "common": "Botswana"
                }
            }
        }
    },
    {
        "flags": ["./img/gh.png"
        ],
        "name": {
            "common": "Ghana",
            "official": "Republic of Ghana",
            "nativeName": {
                "eng": {
                    "official": "Republic of Ghana",
                    "common": "Ghana"
                }
            }
        }
    },
    {
        "flags": ["./img/tv.png"
        ],
        "name": {
            "common": "Tuvalu",
            "official": "Tuvalu",
            "nativeName": {
                "eng": {
                    "official": "Tuvalu",
                    "common": "Tuvalu"
                },
                "tvl": {
                    "official": "Tuvalu",
                    "common": "Tuvalu"
                }
            }
        }
    },
    {
        "flags": ["./img/sn.png"
        ],
        "name": {
            "common": "Senegal",
            "official": "Republic of Senegal",
            "nativeName": {
                "fra": {
                    "official": "République du Sénégal",
                    "common": "Sénégal"
                }
            }
        }
    },
    {
        "flags": ["./img/jo.png"
        ],
        "name": {
            "common": "Jordan",
            "official": "Hashemite Kingdom of Jordan",
            "nativeName": {
                "ara": {
                    "official": "المملكة الأردنية الهاشمية",
                    "common": "الأردن"
                }
            }
        }
    },
    {
        "flags": ["./img/pf.png"
        ],
        "name": {
            "common": "French Polynesia",
            "official": "French Polynesia",
            "nativeName": {
                "fra": {
                    "official": "Polynésie française",
                    "common": "Polynésie française"
                }
            }
        }
    },
    {
        "flags": ["./img/lt.png"
        ],
        "name": {
            "common": "Lithuania",
            "official": "Republic of Lithuania",
            "nativeName": {
                "lit": {
                    "official": "Lietuvos Respublikos",
                    "common": "Lietuva"
                }
            }
        }
    },
    {
        "flags": ["./img/mc.png"
        ],
        "name": {
            "common": "Monaco",
            "official": "Principality of Monaco",
            "nativeName": {
                "fra": {
                    "official": "Principauté de Monaco",
                    "common": "Monaco"
                }
            }
        }
    },
    {
        "flags": ["./img/ss.png"
        ],
        "name": {
            "common": "South Sudan",
            "official": "Republic of South Sudan",
            "nativeName": {
                "eng": {
                    "official": "Republic of South Sudan",
                    "common": "South Sudan"
                }
            }
        }
    },
    {
        "flags": ["./img/np.png"
        ],
        "name": {
            "common": "Nepal",
            "official": "Federal Democratic Republic of Nepal",
            "nativeName": {
                "nep": {
                    "official": "नेपाल संघीय लोकतान्त्रिक गणतन्त्र",
                    "common": "नेपाल"
                }
            }
        }
    },
    {
        "flags": ["./img/de.png"
        ],
        "name": {
            "common": "Germany",
            "official": "Federal Republic of Germany",
            "nativeName": {
                "deu": {
                    "official": "Bundesrepublik Deutschland",
                    "common": "Deutschland"
                }
            }
        }
    },
    {
        "flags": ["./img/mh.png"
        ],
        "name": {
            "common": "Marshall Islands",
            "official": "Republic of the Marshall Islands",
            "nativeName": {
                "eng": {
                    "official": "Republic of the Marshall Islands",
                    "common": "Marshall Islands"
                },
                "mah": {
                    "official": "Republic of the Marshall Islands",
                    "common": "M̧ajeļ"
                }
            }
        }
    },
    {
        "flags": ["./img/td.png"
        ],
        "name": {
            "common": "Chad",
            "official": "Republic of Chad",
            "nativeName": {
                "ara": {
                    "official": "جمهورية تشاد",
                    "common": "تشاد‎"
                },
                "fra": {
                    "official": "République du Tchad",
                    "common": "Tchad"
                }
            }
        }
    },
    {
        "flags": ["./img/zm.png"
        ],
        "name": {
            "common": "Zambia",
            "official": "Republic of Zambia",
            "nativeName": {
                "eng": {
                    "official": "Republic of Zambia",
                    "common": "Zambia"
                }
            }
        }
    },
    {
        "flags": ["./img/mf.png"
        ],
        "name": {
            "common": "Saint Martin",
            "official": "Saint Martin",
            "nativeName": {
                "fra": {
                    "official": "Saint-Martin",
                    "common": "Saint-Martin"
                }
            }
        }
    },
    {
        "flags": ["./img/ec.png"
        ],
        "name": {
            "common": "Ecuador",
            "official": "Republic of Ecuador",
            "nativeName": {
                "spa": {
                    "official": "República del Ecuador",
                    "common": "Ecuador"
                }
            }
        }
    },
    {
        "flags": ["./img/as.png"
        ],
        "name": {
            "common": "American Samoa",
            "official": "American Samoa",
            "nativeName": {
                "eng": {
                    "official": "American Samoa",
                    "common": "American Samoa"
                },
                "smo": {
                    "official": "Sāmoa Amelika",
                    "common": "Sāmoa Amelika"
                }
            }
        }
    },
    {
        "flags": ["./img/bd.png"
        ],
        "name": {
            "common": "Bangladesh",
            "official": "People's Republic of Bangladesh",
            "nativeName": {
                "ben": {
                    "official": "বাংলাদেশ গণপ্রজাতন্ত্রী",
                    "common": "বাংলাদেশ"
                }
            }
        }
    },
    {
        "flags": ["./img/ni.png"
        ],
        "name": {
            "common": "Nicaragua",
            "official": "Republic of Nicaragua",
            "nativeName": {
                "spa": {
                    "official": "República de Nicaragua",
                    "common": "Nicaragua"
                }
            }
        }
    },
    {
        "flags": ["./img/cd.png"
        ],
        "name": {
            "common": "DR Congo",
            "official": "Democratic Republic of the Congo",
            "nativeName": {
                "fra": {
                    "official": "République démocratique du Congo",
                    "common": "RD Congo"
                },
                "kon": {
                    "official": "Repubilika ya Kongo Demokratiki",
                    "common": "Repubilika ya Kongo Demokratiki"
                },
                "lin": {
                    "official": "Republiki ya Kongó Demokratiki",
                    "common": "Republiki ya Kongó Demokratiki"
                },
                "lua": {
                    "official": "Ditunga dia Kongu wa Mungalaata",
                    "common": "Ditunga dia Kongu wa Mungalaata"
                },
                "swa": {
                    "official": "Jamhuri ya Kidemokrasia ya Kongo",
                    "common": "Jamhuri ya Kidemokrasia ya Kongo"
                }
            }
        }
    },
    {
        "flags": ["./img/pk.png"
        ],
        "name": {
            "common": "Pakistan",
            "official": "Islamic Republic of Pakistan",
            "nativeName": {
                "eng": {
                    "official": "Islamic Republic of Pakistan",
                    "common": "Pakistan"
                },
                "urd": {
                    "official": "اسلامی جمہوریۂ پاكستان",
                    "common": "پاكستان"
                }
            }
        }
    }
]