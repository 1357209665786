const result = {
	success: [
		"Wow! amazing, you get +1 point.",
		"Amazing! well done, you get +1 point.",
		"Awesome!, you get +1 point.",
		"Brilliant!, you get +1 point.",
		"You beauty :), you get +1 point.",
		"Outstanding!!!, you get +1 point.",
		"Fantastic!!, you get +1 point.",
	],
	fail: ["Wrong answer!!", "Oops, wrong answer", "Incorrect!", "Wrong, take your time"],
};

export default result;