import React, { useState, useEffect } from "react";
import useSound from 'use-sound';
// components
import Loader from "../loader";
import BoxHeader from "./BoxHeader";
import Flag from "./Flag";
import OptionBox from "./options";
import BoxFooter from "./BoxFooter";
import Modal from "../modal/Modal";
// utils
import Flags from "../../utils/flags";
import resultTexts from "../../utils/result";
// contexts
import FlagsContext from "../../contexts/FlagsContext";
//style
import "./GameBox.css";

//sounds
import click from '../../sounds/click.mp3';
import win from '../../sounds/win.mp3';
import lose from '../../sounds/lose.mp3';

// create instance of Flags
const flags = new Flags();

const GameBox = () => {
  // hooks
  const [isLoading, setIsLoading] = useState(true);
  const [randomFlags, setRandomFlags] = useState([]);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("--Select country--");
  const [currentFlagIndex, setCurrentFlagIndex] = useState(0);
  const [correctAns, setCorrectAns] = useState("");
  const [points, setPoints] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [formattedTime, setFormattedTime] = useState("0:00");
  const [resultText, setResultText] = useState({
    msg: "Take time, Think well",
    isCorrect: null,
  });
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    setRandomFlags(flags.getRandomFlags());
    const [options, correctOption] = flags.getRandomOptions(0);
    setOptions(options);
    setCorrectAns(correctOption);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const [options, correctOption] = flags.getRandomOptions(currentFlagIndex);
    setOptions(options);
    setCorrectAns(correctOption);
  }, [currentFlagIndex]);

  // necessary functions

  // const errorHandler = (error) => {
  // 	setIsLoading(false);
  // 	console.error(error);
  // 	throw new Error("Something went wrong!");
  // };

  useEffect(() => {
    const timerID = setInterval(() => {
      if (isTimerRunning) {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }
      if (currentFlagIndex === 9) {
        setIsTimerRunning(false);
      }
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, [isTimerRunning, currentFlagIndex]);
  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const time = `${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
    return time;
  }
  useEffect(() => {
    setFormattedTime(formatTime(seconds));
  }, [seconds]);

  const sounds = {
    click: useSound(click),
    win: useSound(win),
    lose: useSound(lose),
  };
  
  const handlePlaySound = (sound) => {
    if (sounds[sound]) {
      sounds[sound][0]();
    }
  };

  const handleRestart = () => {
    defaultState();
    setRandomFlags(flags.getRandomFlags());
    setCurrentFlagIndex(0);
    const [options, correctOption] = flags.getRandomOptions(0);
    setOptions(options);
    setCorrectAns(correctOption);
    setPoints(0);
    setSeconds(0);
    setIsTimerRunning(true);
    handlePlaySound('click')
  };

  const handleCurrentFlagIndex = () => {
    setCurrentFlagIndex(currentFlagIndex + 1);
    handlePlaySound('click')
    defaultState();
  };

  const defaultState = () => {
    setSelected("--Select country--");
    setDisableInput(false);
    setResultText({
      msg: "Take time, Think well",
      isCorrect: null,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selected === "--Select country--") {
      return alert("First select a country!");
    } else if (correctAns === selected) {
      handlePlaySound('win')
      setPoints(points + 1);
      setResultText({ msg: genSuccessAnsText(), isCorrect: true });
    } else {
      handlePlaySound('lose')
      setResultText({ msg: genFailAnsText(), isCorrect: false });
    }
    setDisableInput(true);
  };

  const genSuccessAnsText = () => {
    return resultTexts.success[
      Math.floor(Math.random() * resultTexts.success.length)
    ];
  };

  const genFailAnsText = () => {
    return resultTexts.fail[
      Math.floor(Math.random() * resultTexts.fail.length)
    ];
  };

  const handleSelect = (event) => {
    setSelected(event.target.value);
  };

  return (
	
    <FlagsContext.Provider
      value={{ randomFlags, currentFlagIndex, correctAns, points }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="card col-lg-6 col-md-8 col-sm-10 col-12 m-auto mt-3 my-card">
            <BoxHeader formattedTime={formattedTime} points={points} />
            <Flag />
            <OptionBox
              selected={selected}
              options={options}
              handleSubmit={handleSubmit}
              handleSelect={handleSelect}
              disableInput={disableInput}
            />
            <hr className="mb-0" />
            <BoxFooter
              handleCurrentFlagIndex={handleCurrentFlagIndex}
              handleRestart={handleRestart}
              resultText={resultText.msg}
              resState={resultText.isCorrect}
            />
            <div className="privacy-box p-3">
              <div className="privacy">
                <a className="btn btn-privacy" href="./privacy.html">
                  Privacy Policy
                </a>
                <a className="btn btn-privacy" href="./about.html">
                  About Game
                </a>
              </div>
              {currentFlagIndex === 9 && (
                <Modal
                  formattedTime={formattedTime}
                  handleRestart={handleRestart}
                />
              )}
            </div>
          </div>
        </>
      )}
    </FlagsContext.Provider>
  );
};

export default GameBox;
